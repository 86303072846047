import { FarmConfigV3 } from '../src'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

const v3TopFixedFarms: FarmConfigV3[] = [
  
]

export const farmsV3 = defineFarmV3Configs([
  ...v3TopFixedFarms,


])
